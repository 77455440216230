<template>
  <div class="main">
    <van-row style="float:left;margin-top:10px;">
      <van-col span="10" offset="1">
        <van-image
          :src="require('../../../assets/logo.png')"
          style="width:100%"
        />
      </van-col>
      <van-col span="12" offset="1" class="headtext">一条马ERP</van-col>
    </van-row>
    <van-row style="float:left;margin-top:10px;">
      <van-col span="24">
        <!-- Field 是基于 Cell 实现的，可以使用 CellGroup 作为容器来提供外边框。 -->
        <van-cell-group>
          <van-field
            v-model="form.code"
            label="工厂编码"
            placeholder="请输入工厂编码"
          />
        </van-cell-group>
      </van-col>
      <van-col span="24">
        <!-- Field 是基于 Cell 实现的，可以使用 CellGroup 作为容器来提供外边框。 -->
        <van-cell-group>
          <van-field
            v-model="form.user"
            label="登录账号"
            placeholder="请输入登录账号"
          />
        </van-cell-group>
      </van-col>
    </van-row>

    <div class="nav">
      <van-button
        type="success"
        :loading="loading"
        @click="login"
        style="display:block;margin:auto;width:95%;"
        >登录</van-button
      >
    </div>
  </div>
</template>

<script>
import { Row, Col, Icon, Cell, CellGroup, Dialog } from "vant";
import { Field } from "vant";
import { Button } from "vant";
import { Image as VanImage } from "vant";
import { dologin } from "@/config/_api";
import { Overlay } from "vant";
export default {
  components: {
    [Row.name]: Row,
    [Col.name]: Col,
    [Icon.name]: Icon,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [VanImage.name]: VanImage,
    [Field.name]: Field,
    [Button.name]: Button,
    [Overlay.name]: Overlay,
    [Dialog.Component.name]: Dialog.Component,
  },
  data() {
    return {
      form: {
        code: "",
        user: "",
      },
      showDialog: false,
      code: "",
      loading: false,
    };
  },
  created() {},
  methods: {
    experience() {
      this.$router.push("experience");
    },
    async login() {
      this.loading = true;
      if (this.form.code && this.form.user) {
        let { data } = await dologin({
          TenantCode: this.form.code,
          LoginId: this.form.user,
        });
        let { token, userInfo, wages, workload } = data.data;
        if (data.code == 0) {
          Dialog.alert({
            title: "提示",
            message: `${data.msg}`,
          });
        } else {
          localStorage.setItem("token", token);
          localStorage.setItem("userData", JSON.stringify(userInfo));
          localStorage.setItem("wages", wages.toFixed(2));
          localStorage.setItem("workload", workload);
          localStorage.setItem("announcement", 1);
          this.$router.push("tab");
        }
      } else {
        Dialog.alert({
          title: "提示",
          message: "请输入正确的工厂编号和账号",
        });
      }

      this.loading = false;
    },
  },
};
</script>
<style scoped>
.main {
  width: 100%;
  height: 100vh;
  background: #fff;
}
.headtext {
  display: flex;
  align-items: center;
}
.nav {
  float: left;
  margin-top: 10px;
  display: block;
  width: 100%;
}
#formData {
  width: 90%;
  height: 30%;
  background-color: #fff;
  position: relative;
}
.form_bottom {
  width: 100%;
  height: 14%;
  position: absolute;
  border-top: 1px solid #c7c3c3;
  bottom: 0;
}
.form_main {
  width: 100%;
  height: 80%;
  padding: 5%;
}
</style>
